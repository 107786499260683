import React, { useMemo, useRef, useState, useEffect } from "react";
import "../partners/Partners.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { globalConfig } from "../../constants";
import SubHeader from "../../components/SubHeader";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { contact } from "../../services/api";
import { handleFirstNameChange, handleLastNameChange, handleTitleChange, trimFormData, handleCompanyNameChange } from "../../utils/reuse";
import { fetchCountryInfo } from '../../genericFunctions/GetAllCountries';
import { ScrollTo } from "../../utils/ScrollTo";
import { useToast } from "../../context/ToastContext";
import getAssests from "../../assets/data/brandingAssets.json"

const Partners = () => {
  const { partner, labels,validations, isBase64Image, showToast } = useToast();
  const toast = useRef();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [disableBtn, setDisableBtn] = useState(false);
  const [country, setCountry] = useState([]);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.sessionData);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );

  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const formInitialValues = {
    firstName: isAuth ? userData?.firstName : "",
    lastName: isAuth ? userData?.lastName : "",
    email: isAuth ? userData?.email : "",
    body: "",
    company: "",
    title: "",
    type: "partners",
    country: null
  };

  const validationSchema = () => {
    return Yup.object().shape({
        firstName: Yup.string()
        .required("VLD0007")
        .min(3, "VLD0008")
        .max(50, "VLD0008")
        .matches(
          /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/,
          "VLD0018"
      ),
    email: Yup.string()
        .required("VLD0005")
        .email("VLD0006"),
        country: Yup.string()
        .nullable()
        .required("VLD0029"),
      company: Yup.string()
        .min(3, "VLD0032")
        .max(50, "VLD0032")
        .required("VLD0031"),
      body: Yup.string().required("VLD0015"),
    });
  };

  const handleSubmit = (formData) => {
    setDisableBtn(true);
    const contactResponse = (response) => {
      if (response.result === "SUCCESS") {
        formik.resetForm();
        showToast("success",labels?.LBL0281)
      } else {
        const error = response.error;
        const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
        showToast( error.severity, errorMsg)
      }
      setDisableBtn(false);
    };
    const trimmedFormData = trimFormData(formData);
    contact(trimmedFormData, headers, dispatch, contactResponse);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleCountryBlur = () => {
    formik.setFieldTouched('country', true);
  };

  const handleClick = () => {
    ScrollTo(ref, 0);
  };

  useEffect(() => {
    const fetchCountryData = async () => {
      const params = {
        countries: '',
        info: ''
      }
      await fetchCountryInfo(params, dispatch, setCountry, toast, showToast);
    };

    fetchCountryData();
  }, [dispatch, setCountry, toast, showToast]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{globalConfig.domainName} | Partner</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={partner?.PART0001} subText={partner?.PART0002} />
      <PR.Toast ref={toast} position="top-right" />

      <section className="partners-section custom-margin">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-7 md:col-7">
            <div className="partners-text-box flex align-items-center justify-content-center">
              <div>
                <h2>{partner?.PART0004}</h2>
                <p>{partner?.PART0005}</p>
                <Link className="view-all-button" onClick={handleClick}>{partner?.PART0020}</Link>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-5 md:col-5 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(partner.PART0003) ? `data:image/png;base64,${partner.PART0008}` : getAssests.partner?.partner_image}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form-section partners-contact-form" ref={ref}>
        <div className="grid header-text">
          <div className="col-12 text-center">
            <h2>{partner?.PART0012}</h2>
          </div>
        </div>
        <div className="grid header-text">
          <div className="col-12">
            <div className="form-box">
              <form className="contact_form" onSubmit={formik.handleSubmit}
                autoComplete="off">
                <div className="grid mb-3">
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="firstName">{labels?.LBL0001}</label>
                      <span className="p-input-icon-right">
                        <PR.InputText id="firstName" name="firstName" placeholder={labels?.LBL0342} className="w-12" value={formik.values.firstName} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.firstName && formik.touched.firstName ? <div className='error-message'>{validations[formik.errors.firstName]}</div> : ''}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="email">{labels?.LBL0335}</label>
                      <span className="p-input-icon-right">
                        <PR.InputText id="email" name="email" placeholder={labels?.LBL0037} className="w-12" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.email && formik.touched.email ? <div className='error-message'>{validations[formik.errors.email]}</div> : ''}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid mb-3">
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="company">{labels?.LBL0332}</label>
                      <span className="p-input-icon-right">
                        <PR.InputText id="company" name="company" placeholder={labels?.LBL0331} className="w-12" value={formik.values.company} onChange={(e) => handleCompanyNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.company && formik.touched.company ? <div className='error-message'>{validations[formik.errors.company]}</div> : ''}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="country">{labels?.LBL0160}</label>
                      <span className="p-input-icon-right">
                        <PR.Dropdown
                          id="country"
                          name="country"
                          value={formik.values.country}
                          options={country}
                          onChange={formik.handleChange}
                          onHide={handleCountryBlur}
                          resetFilterOnHide
                          optionLabel="label"
                          optionValue="value"
                          showClear placeholder={labels?.LBL0023}
                          style={{ 'width': '100%' }}
                          filter
                          filterBy="label"
                        />
                        {formik.errors.country && formik.touched.country ? <div className='error-message'>{validations[formik.errors.country]}</div> : ''}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-12">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="body">{labels?.LBL0012}</label>
                      <span className="p-input-icon-right">
                        <PR.InputTextarea rows={4} cols={30} maxLength={500} autoResize className="w-12" id="body" name="body" placeholder={labels?.LBL0127} value={formik.values.body} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.body && formik.touched.body ? <div className='error-message'>{validations[formik.errors.body]}</div> : ''}
                      </span>
                    </div>
                  </div>
                  <PR.Button className="contact-button p-button-rounded" type="submit" label={labels?.LBL0014} disabled={!formik.isValid || disableBtn} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Partners;